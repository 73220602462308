import { useState } from 'react'
import { WiDaySunny, WiMoonWaningCrescent4 } from 'react-icons/wi'

const DarkButton = () => {
  const [isDark, setIsDark] = useState(true)
  return (
    <div
      onClick={() => setIsDark(!isDark)}
      className={`${
        isDark ? 'hover:text-purple-400' : 'hover:text-yellow-300'
      } fixed right-2 top-2
       text-3xl cursor-pointer link z-50`}
    >
      {isDark ? <WiMoonWaningCrescent4 /> : <WiDaySunny />}
    </div>
  )
}

export default DarkButton
