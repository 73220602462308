import { useState, useEffect } from 'react'

const Cursor = ({ children }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [cursorClass, setCursorClass] = useState('')
  const [hover, setHover] = useState(false)
  const mouseLeave = () => {

    setCursorClass('cursor-hidden')
  }

  useEffect(() => {
    handleHover()
  })

  const mouseEnter = () => {
    setCursorClass('cursor')
  }

  const mouseMove = (e) => {
    setPosition({ x: e.pageX, y: e.pageY })
    hover ? setCursorClass('cursor-hovered') : setCursorClass('cursor')
  }

  const mouseDown = () => {
    setCursorClass('cursor-clicked')
  }
  const mouseUp = () => {
    setCursorClass('cursor')
  }

  const handleHover = () => {
    document.querySelectorAll('.link').forEach((e) => {
      e.addEventListener('mouseover', () => setHover(true))
      e.addEventListener('mouseout', () => setHover(false))
    })
  }

  const isMobile = () => {
    const ua = navigator.userAgent
    return /Android|Mobi/i.test(ua)
  }

  return (
    <>
      <div
        onMouseLeave={mouseLeave}
        onMouseEnter={mouseEnter}
        onMouseMove={mouseMove}
        onMouseDown={mouseDown}
        onMouseUp={mouseUp}
      >
        {typeof navigator !== 'undefined' && !isMobile() ? (
          <div
            className={cursorClass}
            style={{
              left: `${position.x}px`,
              top: `${position.y}px`,
            }}
          ></div>
        ) : null}
        {children}
      </div>
    </>
  )
}

export default Cursor
