import React from 'react'
import ReactDOM from 'react-dom'
import 'styles/index.css'
import 'styles/cursor.css'
import '@fontsource/ibm-plex-mono/300.css'
import '@fontsource/ibm-plex-mono/700.css'
import App from 'components/App'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
