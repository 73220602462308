import About from 'components/sections/About'
import Contact from 'components/sections/Contact'
import DarkButton from 'components/common/DarkButton'
import Nav from 'components/common/Nav'
import Sketch from 'components/sections/Sketch'
import { ArrowUp } from 'components/common/Arrows'
const Home = ({ toggleDark }) => {
  return (
    <>
      <div className='text-sm bg-gray-100 dark:bg-black text-gray-900 dark:text-gray-200 transition-colors ease-in-out duration-300 leading-loose'>
        <Nav />
        <div onClick={toggleDark}>
          <DarkButton />
        </div>
        <About />
        <Contact />
        <div className='sketch border border-gray-100 dark:border-black w-screen min-h-screen text-center justify-between items-between contact flex-col flex py-10'>
          <ArrowUp scrollLocation='contact' />
          <Sketch />
        </div>
      </div>
    </>
  )
}

export default Home
