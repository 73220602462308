import { Link } from 'react-scroll'
import { CgArrowLongDown, CgArrowLongUp } from 'react-icons/cg'

export const ArrowDown = ({ scrollLocation }) => {
  return (
    <Link
      activeClass='active'
      to={scrollLocation}
      spy={true}
      smooth={true}
      duration={350}
    >
      <div className='flex flex-col justify-end items-end my-4 text-6xl w-screen font-bold '>
        <div className='link animate-pulse hover:text-green-400 z-50'>
          <CgArrowLongDown />
        </div>
      </div>
    </Link>
  )
}

export const ArrowUp = ({ scrollLocation }) => {
  return (
    <Link
      activeClass='active'
      to={scrollLocation}
      spy={true}
      smooth={true}
      duration={350}
    >
      <div className='flex absolute flex-col justify-start items-start my-4 text-6xl w-screen '>
        <div className='link animate-pulse hover:text-green-400 font-bold mt-12 z-50'>
          <CgArrowLongUp />
        </div>
      </div>
    </Link>
  )
}
