import { ArrowUp, ArrowDown } from 'components/common/Arrows'
import Fade from 'react-reveal/Fade'

import { AiOutlineMail, AiOutlineGithub, AiFillLinkedin } from 'react-icons/ai'

const Contact = () => {
  return (
    <div className='flex flex-col items-between h-screen justify-center text-center contact'>
      <ArrowUp scrollLocation='about' />
      <Fade>
        <div className='flex flex-col flex-1 h-full justify-center items-center'>
          <p className='pb-4 md:pb-8 underline'>Contact me:</p>
          <div className='md:p-3 flex flex-col items-center'>
            <p>Email</p>
            <a
              href='mailto:danielirons@protonmail.com'
              className='text-2xl md:text-4xl p-2 link hover:text-green-400'
            >
              <AiOutlineMail />
            </a>
          </div>

          <div className='md:p-3 flex flex-col items-center'>
            <p>GitHub</p>
            <a
              href='https://github.com/dnirns'
              className='text-2xl md:text-4xl p-2 link hover:text-green-400'
            >
              <AiOutlineGithub />
            </a>
          </div>
          <div className='md:p-3 flex flex-col items-center'>
            <p>LinkedIn</p>
            <a
              href='https://www.linkedin.com/in/dan-irons/'
              className='text-2xl md:text-4xl p-2 link hover:text-green-400'
            >
              <AiFillLinkedin />
            </a>
          </div>
        </div>
      </Fade>
      <ArrowDown scrollLocation='sketch' />
    </div>
  )
}

export default Contact
