import { useState } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Home from 'components/screens/Home'
import Cursor from 'components/common/Cursor'


const App = () => {
  const [dark, setDark] = useState(true)
  const toggleDarkMode = () => {
    setDark(!dark)
  }
  return (
    <Router>
      <Cursor>
        <div className={dark && 'dark'}>
          <div className='w-screen h-screen'>
            <Route path='/' exact>
              <Home toggleDark={toggleDarkMode} dark={dark} />
            </Route>

          </div>
        </div>
      </Cursor>
    </Router>
  )
}

export default App
