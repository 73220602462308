import { ArrowDown } from 'components/common/Arrows'
import Fade from 'react-reveal/Fade'
const About = () => {
  return (
    <>
      <div className='flex flex-col justify-between items-center text-center pt-20 h-screen about mx-2'>
        <Fade>
          <div className='flex flex-col flex-1 items-center justify-center leading-10 '>
            <p className='px-2 py-10'>
              I'm Dan, <br /> I'm a front-end developer based in London.
            </p>
          </div>
        </Fade>
        <ArrowDown scrollLocation='contact' />
      </div>
    </>
  )
}

export default About
