import { useState, useRef } from 'react'
import { Stage, Layer, Line } from 'react-konva'

const Sketch = () => {
  const [lines, setLines] = useState([])
  const isDrawing = useRef(false)

  const handleMouseDown = (e) => {
    // document.body.style.overflowY = 'hidden'
    isDrawing.current = true
    const pos = e.target.getStage().getPointerPosition()
    setLines([...lines, { points: [pos.x, pos.y] }])
  }

  const handleMouseMove = (e) => {
    if (!isDrawing.current) {
      return
    }
    const stage = e.target.getStage()
    const point = stage.getPointerPosition()
    let lastLine = lines[lines.length - 1]
    lastLine.points = lastLine.points.concat([point.x, point.y])
    lines.splice(lines.length - 1, 1, lastLine)
    setLines(lines.concat())
  }

  const handleMouseUp = () => {
    // document.body.style.overflowY = 'auto'
    isDrawing.current = false
  }

  return (
    <div className='flex justify-center h-full flex-col items-center'>
      <p className='text-sm underline pb-8'>
        Waste some time and have a doodle:
      </p>
      <Stage
        className='border-2 border-gray-900 dark:border-gray-100 '
        width={window.innerWidth * 0.9}
        height={window.innerHeight * 0.75}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
        onMouseDown={handleMouseDown}
        onMousemove={handleMouseMove}
        onMouseup={handleMouseUp}
      >
        <Layer>
          {lines.map((line, i) => (
            <Line
              key={i}
              points={line.points}
              stroke='lime'
              strokeWidth={10}
              tension={0.5}
              lineCap='round'
              globalCompositeOperation={
                line.tool === 'eraser' ? 'destination-out' : 'source-over'
              }
            />
          ))}
        </Layer>
      </Stage>
    </div>
  )
}

export default Sketch
