import { Link } from 'react-scroll'

const Nav = () => {
  return (
    <div className='pl-2 fixed top-0 w-full py-2 px-2 flex justify-start text-sm bg-gradient-to-r from-gray-100 dark:from-black z-20 font-bold'>
      <Link
        activeClass='active'
        to='about'
        spy={true}
        smooth={true}
        duration={350}
      >
        <p className='px-2 link '>About</p>
      </Link>
      <Link
        activeClass='active'
        to='contact'
        spy={true}
        smooth={true}
        duration={350}
      >
        <p className='px-2 link'>Contact</p>
      </Link>
      <Link
        activeClass='active'
        to='sketch'
        spy={true}
        smooth={true}
        duration={350}
      >
        <p className='px-2 link'>Draw</p>
      </Link>
    </div>
  )
}

export default Nav
